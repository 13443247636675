import React, { useState } from "react";
import Logo from "../../assets/icons/breakfast_club_logo.svg";
import Pancake from "../../assets/icons/pankie.svg";
import PopUp from '../popup/popup';
import "./navbar.css"

export default function Navbar() {

	const [isOpen, ToggleIsOpen] = useState(false);

	function OnOpen(event) { event.preventDefault(); ToggleIsOpen(true); }

	function OnClose(event) { event.preventDefault(); ToggleIsOpen(false); }

	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

	return (

		<nav class="navbar navbar-expand-md navbar-light bg-primary text-white">
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
				<img src={Pancake} width="45" height="45" class="d-inline-block align-top mx-2" alt="Breakfastclub Logo" />
			</button>
			<div class="collapse navbar-collapse" id="navbarNavDropdown">
				<a class="navbar-brand text-white d-none d-md-block" href="#">
					<img src={Logo} width="45" height="45" class="d-inline-block align-top mx-2" alt="Breakfastclub Logo" />
				</a>
				<ul class="navbar-nav">
					<li class="nav-item ">
						<a class="nav-link text-white active" href="#" onClick={() => openInNewTab('https://www.beyondmenu.com/38664/oviedo/breakfast-club-of-oviedo-oviedo-32765.aspx')} >MENU <span class="sr-only">(current)</span></a>
					</li>
					<PopUp isOpen={isOpen} OnClose={OnClose}>
						Continue with:
					</PopUp>
					<li class="nav-item">
						<a class="nav-link text-white active" onClick={OnOpen} href="#">ORDER ONLINE</a>
					</li>
					<li class="nav-item">
						<a class="nav-link text-white active" href="#AboutUs">ABOUT US</a>
					</li>
					<li class="nav-item">
						<a class="nav-link text-white active" href="#Info">CONTACT</a>
					</li>

				</ul>
			</div>
			<a href="tel:4075421521" class="nav-link d-none d-md-block text-white">(407) 542-1521</a>
			<img src={Logo} width="45" height="45" class="d-inline-block d-md-none align-top" alt="Breakfastclub Logo" />
			<div>
				<a href="https://goo.gl/maps/aJhSYLqAEVney1378" class="whitelink">
					<svg xmlns="http://www.w3.org/2000/svg"
						width="30"
						height="30"
						fill="currentColor"
						class="bi bi-geo-alt-fill mr-2 d-md-none"
						viewBox="0 0 16 16">
						<path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
					</svg>
				</a>
				<a href="tel:4075421521" class="whitelink">
					<svg xmlns="http://www.w3.org/2000/svg"
						width="30"
						height="30"
						fill="currentColor"
						class="bi bi-telephone mx-2 d-md-none"
						viewBox="0 0 16 16">
						<path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
					</svg>
				</a>
			</div>
		</nav>
	)
}