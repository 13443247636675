import React from 'react';
import ReactDOM from 'react-dom';
import './popup.css';
import grubhub from '../../assets/images/grubhub1.png';
import ubereats from '../../assets/images/ubereats.png';

// jshint ignore : start

export default function PopUp(props) {
    // don't popup unless flag is true
    if (!props.isOpen) { return null; }

    return ReactDOM.createPortal(
        <div>
            <div class="overlay"></div>
            <div class="popup">
                <div>{props.children}</div>
                <p></p>
                {/* opens up a new tab to which ever link(s) is/are clicked on */}
                <div><img class="icon" src={grubhub} alt="null" />&nbsp;&nbsp;<a class="rid_underline" href="https://www.grubhub.com/restaurant/breakfast-club-of-oviedo-1977-alafaya-trail-oviedo/2262522" target="_blank" rel="noopener noreferrer">grubhub.com</a></div>
                &nbsp;
                <div><img class="icon" src={ubereats} alt="null" />&nbsp;&nbsp;<a class="rid_underline" href="https://www.ubereats.com/orlando/food-delivery/breakfast-club-oviedo/OCZ48DGxR_2oQOx_-0nv9w" target="_blank" rel="noopener noreferrer">ubereats.com</a></div>
                <p></p>
                <button class="btn btn-primary" onClick={props.OnClose}>Close</button>
            </div>
        </div>,
        document.getElementById('portal')
    );
}
