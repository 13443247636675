
import React, { useState } from "react";
import Phone from "../../assets/icons/telephone.svg";
import Geo from "../../assets/icons/geo-alt.svg";
import Email from "../../assets/icons/envelope.svg";
import Clock from "../../assets/icons/clock.svg";
import "./info.css"

export default function Carousel() {

    return (

        <div id="Info" class="info">
            <div class="row info">
                <div class="col-lg-3 col-md-6 ">
                    <img src={Geo} width="30" height="30" class="d-inline-block align-top m-2" alt="Instagram" />
                    <h4>Location:</h4>
                    <p>1977 Alafaya Trail #1001<br />Oviedo, FL 32765</p>
                </div>

                <div class="col-lg-3 col-md-6  mt-4 mt-lg-0">
                    <img src={Clock} width="30" height="30" class="d-inline-block align-top m-2" alt="Instagram" />
                    <h4>Open Hours:</h4>
                    <p>Monday-Saturday: 6:00 AM - 3:00 PM <br /> Sunday: 7:00 AM - 3:00 PM </p>
                </div>

                <div class="col-lg-3 col-md-6  mt-4 mt-lg-0">
                    <img src={Email} width="30" height="30" class="d-inline-block align-top m-2" alt="Instagram" />
                    <h4>Email:</h4>
                    <p>breakfastclub1977@gmail.com.com</p>
                </div>

                <div class="col-lg-3 col-md-6  mt-4 mt-lg-0">
                    <img src={Phone} width="30" height="30" class="d-inline-block align-top m-2" alt="Instagram" />
                    <h4>Call:</h4>
                    <p>+1 (407)-542-1521</p>
                </div>
            </div>
            <hr class="featurette-divider" />
        </div>

    )
}

