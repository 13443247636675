import React from "react"
import "./footer.css"
import Facebook from "../../assets/icons/facebook.svg";
import Instagram from "../../assets/icons/instagram.svg";
import phoneIcon from "../../assets/icons/telephone-fill.svg";


export default function Footer() {

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <footer class="text-muted py-5 ">
            <div class="container footer_1 bg-primary text-white">
                <div class="container">
                    <div class="row footer_1">
                        <h2>Oviedo Breakfast Club</h2>
                    </div>
                    <div class="row  footer_1">
                        <h4>Where neighbors meet and eat</h4>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <a href="#" onClick={() => openInNewTab('https://www.facebook.com/thebreakfastclubofoviedo/')} class="whitelink">
                                <img src={Facebook} width="30" height="30" class="d-inline-block align-top m-2" alt="Instagram" />
                            </a>
                            <a href="#" onClick={() => openInNewTab('https://www.instagram.com/explore/locations/809070519/oviedo-breakfast-club/')} class="whitelink">
                                <img src={Instagram} width="30" height="30" class="d-inline-block align-top m-2" alt="Instagram" />
                            </a>
                            <a href="tel:4075421521" class="whitelink">
                                <img src={phoneIcon} width="30" height="30" class="d-inline-block align-top m-2" alt="Instagram" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <a class="text-white" href="#">Back to top</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}