import React from "react"
import waffie from '../../assets/images/waffie.jpg'


export default function Specials() {

    return (

        <div id="AboutUs" class="container marketing">


            <div class="row">
            </div>
            <hr class="featurette-divider" />

            <div class="row featurette">
                <div class="col-md-7 order-md-2">
                    <h2 class="featurette-heading">Come Hungry, <span class="text-muted">Leave Satisfied.</span></h2>
                    <p class="lead">The Breakfastclub was established in 2013, and revitalized in 2020. We make everything in house. From burgers, hand-cut steaks, fried chicken, or delicious breakfast. We value teamwork, honesty, community involvement, dedication to the culinary arts, and transparency.</p>
                </div>
                <div class="col-md-5 order-md-1">
                    <img class="img-fluid mx-auto" preserveAspectRatio="xMidYMid slice" src={waffie} width="450" height="450" />

                </div>
            </div>

            <hr class="featurette-divider" />

        </div>
    )
}