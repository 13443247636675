import React from 'react'
import './infoCards.css'
import breakfastclubLogo from "../../assets/icons/breakfast_club_logo.svg"
import cleanIcon from "../../assets/icons/clean.svg"
import maskIcon from "../../assets/icons/mask.svg"


export default function InfoCards() {


	return (
		<div class="container">
			<div class="row">
				<div class="card-group">
					<div class="m-3 bg-primary infocard text-white card ">
						<img src={breakfastclubLogo} width="50" height="50" class="card-img-top my-3" alt="..." />
						<div class="card-body">
							<p class="card-text">The Oviedo Breakfast Club is focused on providing high-quality service and customer satisfaction.</p>
						</div>
					</div>
					<div class="m-3 bg-primary infocard  text-white card">
						<img src={cleanIcon} width="50" height="50" class="card-img-top my-3" alt="..." />
						<div class="card-body">
							<p class="card-text">Our team is working extra hard to ensure you have a clean and safe dining experiance</p>
						</div>
					</div>
					<div class="m-3 bg-primary infocard  text-white card">
						<img src={maskIcon} width="35" height="35" class="card-img-top my-4" alt="..." />
						<div class="card-body">
							<p class="card-text">We are complying with all the mandatory safety measure to ensure your saftey during these challenging times</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}