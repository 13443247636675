import Navbar from './components/navbar/nav.js';
import InfoCards from './components/cards/infoCards.js';
import Info from './components/infoSection/info.js';
import Specials from './components/specials/specials.js';
import Carousel from './components/carousel/carousel.js';
import Footer from './components/footer/footer.js';
import './App.css';

// jshint ignore : start

function App() {
  return (
    <div>
      <Navbar />
      <Carousel />
      <InfoCards />
      <Specials />
      <Info />
      <Footer />


    </div>
  );
}

export default App;
