import React, { useState } from "react";
import Multiplate from '../../assets/images/carousel1/multiPlate.jpg'
import ChickNWafs from '../../assets/images/carousel1/ChickNWafs.jpg'
import BreakfastClub from '../../assets/images/carousel1/BreakfastClub.png'
import Mimosa from '../../assets/images/carousel1/desktop-mimosa.jpg'
import PopUp from '../popup/popup';
import "./carousel.css"


export default function Carousel() {

    const [isOpen, ToggleIsOpen] = useState(false);
    let image1 = Multiplate;
    let image2 = Mimosa;
    let image3 = ChickNWafs;

    if (detectMob()) {
        image1 = BreakfastClub
        image2 = BreakfastClub
        image3 = BreakfastClub
    }
    else {
        image1 = Mimosa
        image2 = Multiplate
        image3 = Mimosa
    }

    function OnOpen(event) { event.preventDefault(); ToggleIsOpen(true); }

    function OnClose(event) { event.preventDefault(); ToggleIsOpen(false); }

    function detectMob() {
        return ((window.innerWidth <= 800));
    }
    console.log("are we a mobile?", detectMob())

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="d-block img" src={image1} alt="First slide" />
                    <div class="container">
                        <div class="carousel-caption text-start">
                            <h1>Breakfast Club of Oviedo</h1>
                            <h3>Where neighbors meet and eat</h3>
                            <p><a class="btn btn-lg btn-primary" href="#" onClick={OnOpen} role="button">ORDER ONLINE</a></p>
                            <PopUp isOpen={isOpen} OnClose={OnClose}>
                                Continue with:
				        	</PopUp>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <img class="d-block img" src={image2} alt="Second slide" />
                    <div class="container">
                        <div class="carousel-caption text-start">
                            <h1>Check out one of our fantastic menu items today</h1>
                            <p><a class="btn btn-lg btn-primary" href="#" onClick={() => openInNewTab('https://www.beyondmenu.com/38664/oviedo/breakfast-club-of-oviedo-oviedo-32765.aspx')} role="button">SEE MENU</a></p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <img class="d-block img" src={image3} alt="Third slide" />
                    <div class="container">
                        <div class="carousel-caption text-start">
                            <h1>Call us now and make a reservation</h1>
                            <p><a class="btn btn-lg btn-primary" href="tel:4075421521" role="button">CLICK TO CALL</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div >
    )
}